body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.SpinnerWrapper {
  display: block;
  position: fixed;
  z-index: 1031;
  /* High z-index so it is on top of the page */
  top: 50%;
  width: 100%;
  text-align: center;
}

.spinner-border-l {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.SpinderCaption {
  font-size: 18px;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: #007bff;
  font-size: 10px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  /* text-indent: -9999em; */
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader-overlay.full-loader {
  z-index: 99999;
}

.pointerEventsnone {
  pointer-events: none !important;
}

.fontWeightBold {
  font-weight: bold !important;
}
.download-review-btn .btn-outline-secondary {
    border-color: rgba(65, 64, 66, 0.8);
    color: rgba(65, 64, 66, 0.8);
}
.cart-box .banner-cart-text h4 {
    color: #323E48;
}
.cart-box .banner-cart-text h5 {
    color: #323E48;
}
.download-review-btn .btn-outline-secondary:hover {
  color: #ffffff;
}
.select-contact-search .form-check-input[type="radio"]:checked ~ .form-check-label:after, .select-contact-search .form-check-input[type="checkbox"]:checked ~ .form-check-label:after {
    height: 24px;
    width: 24px;
    left: 0;
}
.optional .form-check-input[type="radio"]:checked ~ .form-check-label:after, .optional .form-check-input[type="checkbox"]:checked ~ .form-check-label:after {
    height: 24px;
    width: 24px;
    left: 0;
}
.checkout-content.step-form-4 .form-group .form-check {
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
}
.select-contact-search.open-select .dropdown:after {
	content: "";
	right: 8px;
	transform: rotate(180deg);
}
.form-select-dropdown:after {
  display: none;
}

.form-select-dropdown select{
  background-image: none !important;
}
.open-select .form-select-dropdown:before{
    content: "";
    right: 8px;
    transform: rotate(180deg);
    z-index: 999;
}

.border-red {
      border: 1px solid #dc3545 !important;
}
.select-badge .badge.bg-primary {
    background-color: #00BBE2 !important;
    margin-right: 8px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.select-badge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.select-badge span.badge.close-badge {
    font-size: 9px;
    border-radius: 100%;
    font-weight: 700;
    opacity: 1;
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    width: 15px;
    height: 15px;
    justify-content: center;
}
.select-contact-search ul.dropdown-menu {
    z-index: 999999;
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 15px;
}
.checkout-content .point-of-contact a {
  border-radius: 8px;;
}
.count-view-download select {
    max-width: 58px;
    width: 1000%;
    padding-right: 36px;
}
.count-view-download select {
    max-width: 68px;
    margin-right: 30px;
}
.count-view-download .form-select-dropdown:before {
    content: "";
    width: 30px;
    background-position: 100% 0%;
    right: 19px;
}
.count-view-download.open-select .form-select-dropdown:before {
    content: "";
    width: 20px;
    background-position: 0% 130%;
    right: 19px;
}
.checkout-content .nav-item {
    margin-bottom: 10px;
}
.checkout-content .nav-tabs {
    margin-bottom: 60px;
}
.checkout-content.add-services-new-form {
    max-width: 100%;
}
.question-box-border {
  border: 1px solid #cccccc;
  padding:10px;
  margin-bottom: 15px;
}
.feature-data-border .optional {
    padding: 10px;
    border: 1px solid #cccccc;
    margin-bottom: 20px;
}
.feature-data-border .optional textarea {
    margin-bottom: 15px;
}
.select-checkbox-new input {
  margin-left: 10px;
}
.add-services-new-form .optional-title {
    margin-bottom: 20px;
}
.add-services-new-form .form-group.form-upload {
    margin-top: 31px;
}
.add-services-new-form .feature-data-border .optional-title {
    border-bottom: 1px solid #f1f1f1;
}
.add-services-new-form .feature-data-border .optional-title.border-none {
    border: none;
}
.summary-of-services-select .form-check label {
    font-size: 18px !important;
    padding-left: 10px !important;
    line-height: 1.5 !important;
}
.summary-of-services-select .form-check {
    max-width: 194px !important;
    margin-right: 15px !important;
    margin-bottom: 10px;
    justify-content: inherit;
}
.summary-of-services-select {
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
}
.summary-of-services-select {
    flex-wrap: wrap;
}

@media(max-width:991px){
  .checkout-content .point-of-contact {
      margin-bottom: 40px;
      max-width: 150px;
      margin-left: auto;
  }
  .point-of-contact a.add-minus {
    margin-right: 15px;
  }
  .point-of-contact a.add-minus {
    margin-right: 15px;
    max-width: 75px;
  }
  .checkout-content .point-of-contact {
    min-width: 75px;
    max-width: 150px;
  }
  .checkout-content .point-of-contact a {
      max-width: 75px;
  }
}

.profile-dropdown{
  left: -32px!important;
}